<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>

    <v-card
      v-else
      :min-width="minWidth"
      :max-width="maxWidth"
      :outlined="outlined"
    >
      <v-card-title class="subheading mb-n14">
        <v-spacer></v-spacer>
        <leave-status-chip :status="leave.status"></leave-status-chip>
        <v-btn
          v-if="showCloseButton"
          :color="$vuetify.theme.dark ? '' : 'secondary'"
          icon
          @click="$emit('closeEvent')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-badge-account</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle>{{
            leave.user.displayName
          }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-question</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle
            ><leave-reason-chip :reason="leave.reason"></leave-reason-chip
          ></v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-clock-start</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle
            >{{ timestampConverter(leave.duration.start.timestamp) }}
            Uhr
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-clock-end</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle
            >{{ timestampConverter(leave.duration.end.timestamp) }}
            Uhr
            <br class="hidden-md-and-up uppercase" />
            <v-chip
              v-if="leave.duration.end.isOpen"
              :x-small="mobile"
              :small="!mobile"
              class="font-weight-medium red--text text--darken-4"
              color="red lighten-4"
              >Endzeit offen</v-chip
            >
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-percent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <date-progress-bar
              :start="leave.duration.start.timestamp"
              :end="leave.duration.end.timestamp"
              :color="`${getStatusColor(leave.status)} lighten-4`"
            ></date-progress-bar>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="leave.reason.details">
          <v-list-item-icon>
            <v-icon>mdi-card-text</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle style="white-space: normal">{{
            leave.reason.details
          }}</v-list-item-subtitle>
        </v-list-item>
      </v-card-text>
      <v-card-actions v-if="showDetailsButton"
        ><v-spacer></v-spacer
        ><v-btn
          small
          depressed
          :to="{
            name: 'availability-leaves-details',
            params: { itemId: leave.meta.id },
          }"
          ><v-icon small class="mr-1">mdi-information-outline</v-icon
          >Details</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import LeaveStatusChip from "@/components/availability/leaves/LeaveStatusChip.vue";
import LeaveReasonChip from "@/components/availability/leaves/LeaveReasonChip.vue";
import DateProgressBar from "@/components/_systemwide/DateProgressBar.vue";
export default {
  name: "leave-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      required: false,
      default: undefined,
    },
    maxWidth: {
      required: false,
      default: undefined,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showDetailsButton: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    leave: {
      type: Object,
    },
  },
  components: { LeaveStatusChip, LeaveReasonChip, DateProgressBar },
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case "active":
          return "red";
        case "upcoming":
          return "orange";
        case "finished":
          return "green";
        default:
          return "grey";
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
